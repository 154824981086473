<template>
  <div>
    <van-nav-bar @click-left="this.$router.go(-1)"></van-nav-bar>
    <section style="padding: 30px 30px; display: flex; flex-flow: column nowrap;">
      <div style="font-size: 20px; font-weight: bold;">用户信息</div>
      <div>
        <form>
          <van-cell-group>
            <van-field
                ref="phone"
                v-model="username"
                clearable
                placeholder="请输入姓名"
                style="background-color: WhiteSmoke"
            >
            </van-field>
            <van-field
                ref="pwd"
                v-model="idcard"
                center
                clearable
                @blur="blur"
                placeholder="请输入身份证号"
                style="background-color: WhiteSmoke"
            >
            </van-field>
          </van-cell-group>
        </form>
      </div>
      <div>
        <van-button :disabled="loginDisabled"  :type="type" block round @click="login" :loading="loading" loading-text="请求中...">下一步</van-button>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import {isPhone} from "@/utils/commons";
import {Toast} from 'vant';
import qs from "qs";
export default {
  name: "LoginByPwd",
  components: {NavBar},
  mounted() {
    // 输入框聚焦
    if (!this.tel) {
      this.$refs.phone.focus();
    } else {
      this.$refs.pwd.focus();
    }
  },
  watch: {
    // 监听身份证号
    username(newVal) {
      if (newVal && newVal.length >=2 && this.idcard && this.idcard.length===18) {
        this.loginDisabled = false;
        this.type = 'danger'
      } else {
        this.loginDisabled = 'disabled';
        this.type = 'danger'
      }
    },

    idcard(newVal) {
      if (newVal && newVal.length === 18 && this.username && this.username.length>=2) {
        this.loginDisabled = false;
        this.type = 'danger'
      } else {
        this.loginDisabled = 'disabled';
        this.type = 'danger'
      }
    }
  },
  data() {
    return {
      // 登录按钮类型
      type: 'danger',
      // 用户名
      username: '',
      // 身份证号
      idcard: '',
      // 登录按钮是否禁用
      loginDisabled: 'disabled',
      loading: false
    }
  },
  mounted() {
    this.$store.commit('toggleTabbarShow', false)
  },
  methods: {
    blur() {
      // 修改store中phone
      if (isPhone(this.tel)) {
        this.$store.commit('updatePhone', this.tel)
      }
    },
    // 登录
    login() {

      if (!this.username || !this.idcard) {
        Toast("请输入")
        return
      }

      this.$http.put("/customer/"+qs.parse(localStorage.getItem('userInfo')).id+"/",{ name: this.username, id_card: this.idcard })
          .then(res => {
            console.log(res);
            if (res && res.code === 1000) {
              this.$router.push('/exam')
            }
          }).finally(()=> {
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        this.finished = true;
        this.submitting = false
      })
    },
    // 去找回密码
    toRetrievePwd() {
      if (!isPhone(this.tel)) {
        this.errMsg = "手机号填写错误";
        this.$refs.phone.focus()
      } else {
        this.$router.push('/retrievePwd');
        this.errMsg = ''
      }
    }
  }
}
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}
</style>
