<template>
  <div>
    <nav-bar></nav-bar>
    <section style="padding: 30px 30px; display: flex; flex-flow: column nowrap;">
      <div style="font-size: 20px; font-weight: bold;">手机号密码登录</div>
      <div>
        <form>
          <van-cell-group>
            <van-field
                ref="phone"
                v-model="tel"
                clearable
                placeholder="请输入手机号"
                style="background-color: WhiteSmoke"
                @blur="blur"
            >
            </van-field>
            <van-field
                ref="pwd"
                v-model="pwd"
                autocomplete="off"
                center
                clearable
                placeholder="请输入密码"
                style="background-color: WhiteSmoke"
                type="password"
            >
            </van-field>
          </van-cell-group>
        </form>
        <span v-show="errMsg" style="color: red">{{ errMsg }}</span>
      </div>
      <div>
        <van-button :disabled="loginDisabled" :type="type" block round @click="login" :loading="loading" loading-text="登录中...">登录</van-button>
      </div>
      <div><slot></slot></div>
      <div v-if="false">
        忘记了？&nbsp;<a @click="toRetrievePwd">找回密码</a>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import {isPhone} from "@/utils/commons";
import {loginByPwd} from "@/api";

export default {
  name: "LoginByPwd",
  components: {NavBar},
  mounted() {
    // 输入框聚焦
    if (!this.tel) {
      this.$refs.phone.focus();
    } else {
      this.$refs.pwd.focus();
    }
  },
  watch: {
    // 监听手机号和密码输入框，修改登录按钮状态和类型
    pwd(newVal) {
      if (isPhone(this.tel) && newVal.length > 0) {
        this.loginDisabled = false;
        this.type = 'danger'
      } else {
        this.loginDisabled = 'disabled';
        this.type = 'danger'
      }
    },
    tel(newVal, oldVal) {
      this.tel = this.tel.replace(/\s+/g, '').replace(/\D/g, '');
      if (isNaN(newVal) || newVal.length > 11) {
        this.tel = oldVal;
        return
      }
      if (isPhone(this.tel) && this.pwd.length > 0) {
        this.loginDisabled = false;
        this.type = 'danger'
      } else {
        this.loginDisabled = 'disabled';
        this.type = 'danger'
      }
    }
  },
  data() {
    return {
      // 登录按钮类型
      type: 'danger',
      // 手机号
      tel: this.$store.state.phone,
      // 密码
      pwd: '',
      // 错误提示信息
      errMsg: '',
      // 登录按钮是否禁用
      loginDisabled: 'disabled',
      loading: false
    }
  },
  methods: {
    blur() {
      // 修改store中phone
      if (isPhone(this.tel)) {
        this.$store.commit('updatePhone', this.tel)
      }
    },
    // 登录
    login() {
      let params = {
        username: this.tel,
        password: this.pwd
      };
      this.loading = true
      loginByPwd(params)
          .then(res => {
            console.log(res);
            if (res) {
              if (res.code !== 1000) {
                this.errMsg = res.msg;
                // 密码输入错误时重新聚焦输入框
                this.$refs.pwd.focus();
              } else {
                res.user.token = res.jwt
                this.$store.commit('login', res.user);
              }
            }
          }).finally(() => {
        this.loading = false
      })
    },
    // 去找回密码
    toRetrievePwd() {
      if (!isPhone(this.tel)) {
        this.errMsg = "手机号填写错误";
        this.$refs.phone.focus()
      } else {
        this.$router.push('/retrievePwd');
        this.errMsg = ''
      }
    }
  }
}
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}
</style>
