<template>
  <van-nav-bar>
    <van-icon slot="left" color="#fff" name="arrow-left" size=".46rem" @click="back"></van-icon>
  </van-nav-bar>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
