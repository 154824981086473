// function isPhone(phone) {
//   let reg = /^[1][345789]\d{9}$/;
//   return reg.test(phone)
// }

function isPhone(phone) {
  let reg = /^[1][1234567890]\d{9}$/;
  return reg.test(phone)
}

function isCode(code) {
  return code.length === 6;
}


export {isPhone, isCode}
