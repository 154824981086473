<template>
  <div>
    <nav-bar/>
    <section style="padding: 30px 30px; display: flex; flex-flow: column nowrap;">
      <div style="font-size: 20px; font-weight: bold">请输入验证码</div>
      <div style="color: #999999">验证码以短信的形式发送至：{{ tel }}</div>
      <div>
        <van-cell-group>
          <van-field
              ref="code"
              v-model="pic_code"
              center
              clearable
              placeholder="请输入图片验证码"
              style="background-color: WhiteSmoke;height: 60px; padding-right: 10px;"
          >
            <template #button>
              <div style="text-align: center;width: 160px; height: 60px;line-height: 60px;padding: 0;margin: 0">
                <van-loading type="spinner" v-if="img === undefined" />
                <img v-if="img!==undefined" :src="img" style="width: 160px; height: 50px; padding-right: 0px; margin-right: 0px;" @click="picCode"  />
              </div>
            </template>
          </van-field>
          <van-field
              ref="code"
              v-model="sms"
              center
              clearable
              placeholder="请输入手机验证码"
              style="background-color: WhiteSmoke;height: 60px;"
          >
            <van-button slot="button" :disabled="disabled" type="danger" :loading="send_loading" loading-text="发送中..." plain round @click="sendCode">{{
                text
              }}
            </van-button>
          </van-field>
        </van-cell-group>
        <span v-show="errMsg" style="color: red">{{ errMsg }}</span>
        <slot name="msg"></slot>
      </div>
      <div>
        <van-button :disabled="loginDisabled" :type="type" block round :loading="loading" loading-text="登录中..." @click="login">登录</van-button>
      </div>
      <div><slot></slot></div>
    </section>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import {isCode} from '@/utils/commons'
import {getPictureCode, loginByCode, sendLoginCode} from "@/api";
import {Toast} from "vant";

export default {
  name: "LoginByCode",
  components: {NavBar},
  watch: {
    sms(newVal, oldVal) {
      this.sms = this.sms.replace(/\s+/g, '').replace(/\D/g, '');
      if (isNaN(newVal) || newVal.length > 6) {
        this.sms = oldVal;
      }
      if (isCode(newVal)) {
        this.type = 'danger';
        this.loginDisabled = false
      } else {
        this.type = 'danger';
        this.loginDisabled = 'disabled'
      }
    }
  },
  mounted() {
    this.$refs.code.focus()
  },
  data() {
    return {
      tel: this.$store.state.phone,
      sms: '',
      text: '获取验证码',
      disabled: false,
      loginDisabled: 'disabled',
      type: 'danger',
      errMsg: '',
      img: undefined,
      code: '',
      pic_code: undefined,
      loading: false,
      send_loading: false,
    }
  },
  methods: {
    picCode() {
      this.pictureCode()
    },
    // 图片验证码
    pictureCode(){
      this.img = undefined
      getPictureCode().then(res => {
        console.log(res);
        if (res && res.code === 1000) {
          this.code = res.pc_id
          this.img = 'data:image/png;base64,' + res.image.replace(/\\r\\n/g, '')
        }
      })
    },
    // 验证码登录
    login() {
      let params = {
        username: this.tel,
        verification_code: this.sms
      }
      this.loading = true
      loginByCode(params)
          .then(res => {
            console.log('login-->', res);
            if (res){
              if (res.code === 1000) {
                res.user.token = res.jwt
                this.$store.commit('login', res.user);
              } else {
                this.errMsg = res.msg
              }
            }
          }).finally(() => {
        this.loading = false
      })
    },
    // 发送验证码
    sendCode() {
      if (!this.pic_code) {
        Toast('请输入图片验证码')
        return
      }
      let params = {
        phone_number: this.tel,
        pc_id: this.code,
        picture_code: this.pic_code
      };
      this.send_loading = true
      sendLoginCode(params).then(data => {
        console.log(data);
        if (data) {
          this.disabled = 'disabled';
          this.timer()
          if (data.code === 1000){
            Toast.success("验证码已发送")
          }
        }
      }).finally(()=>{
        this.send_loading = false
      });
    },
    // 60s计时器
    timer() {
      let i = 60;
      this.text = i;
      let timerId = setInterval(() => {
        if (i === 0) {
          clearInterval(timerId);
          this.text = "重新发送";
          this.disabled = false;
          return
        }
        this.text = --i;
      }, 1000);
    }
  },
  created() {
    this.pictureCode();

  }
}
</script>

<style scoped>
section div {
  margin-bottom: 16px;
}
</style>
